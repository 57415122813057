.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0px;
}

video {
  background: #222;
  margin: 0 0 20px 0;
  --width: 100%;
  width: var(--width);
  height: calc(var(--width) * 0.75);
}

#player {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #323232;
}

#player:before {
  content: "";
  display: block;
  padding-top: 66%;
}

#playButton {
  width: 15%;
  max-width: 200px;
  cursor: pointer;
}

#Video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#VideoThumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 30%;
}

#greenButton {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 160px;
  background-color: #4caf50;
  /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

#blueButton {
  position: absolute;
  bottom: 10px;
  left: 180px;
  width: 160px;
  background-color: #447faf;
  /* Blue */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

#orangeButton {
  position: absolute;
  bottom: 10px;
  left: 350px;
  width: 160px;
  background-color: #ff7700;
  /* Blue */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

#fullscreenButton {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 32px;
  height: 32px;
}

#dateOfBirth {
  color: rgb(43, 40, 40);
}

#education {
  color: rgb(43, 40, 40);
}
.ant-select-selection-item {
  color: rgb(43, 40, 40);
}

.ant-input .ant-input-disabled {
  color: rgb(43, 40, 40);
}
.input-disabled {
  color: rgb(43, 40, 40);
}